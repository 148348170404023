import * as React from "react";

import {
  Box,
  Button,
  Grid,
  Link,
  Typography
} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';

import { SimpleShowLayout, Show, TextField, EmailField, DateField, ChipField, ReferenceField, FunctionField } from "react-admin";

import { useAuth0 } from '../../hooks/use-auth0';
import { USDFormatter } from "../../utils";

import ProvenanceMap from "./ProvenanceMap";

const MOD_API_URL = process.env.GATSBY_MOD_API_URL;

export const OrderShow = () => {
  const { authFetch } = useAuth0();
  const [order, setOrder] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [provenance, setProvenance] = React.useState({});

  //temp until we get this cached in stac item
  React.useEffect(() => {
    const loadProvenance = async() => {
      let url = `${MOD_API_URL}/admin/orders/${order.id}/provenance.json`;
      try {
        const { status, json } = await authFetch(url);
      
        if (status === 200) {
          setLoading(false);
          setProvenance(json);
        }
        else if (status === 404) {
          setLoading(false);
          setProvenance({'error': true})
        }
      } catch(e) {
        setLoading(false);
        console.error(e)
      }
    }

    if (!order || !order.id) {
      console.error('no order yet')
      return null;
    } else {
      setLoading(true);
    }

    if(Object.keys(provenance).length === 0) {
      loadProvenance();
    }
     // eslint-disable-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Show>
       <Grid container>
          <Grid item md={2}>
            <SimpleShowLayout>
              <ReferenceField source="id" reference="orders" link="show" />
              <DateField source="date_created" />
              <FunctionField source="aoi_label" label="AOI" render={r => (
                <Link href={`http://geojson.io/#data=data:text/x-url,${r.aoi_geojson_path}`} target="_blank">{r.aoi_label}</Link>
              )} />
              <EmailField source="email" />
              <DateField source="start_date" />
              <DateField source="end_date" />
              <TextField source="product_id" />
              <FunctionField source="cost" render={r => (r.cost > 0) && USDFormatter.format(r.cost / 100)}/>
              <ChipField source="paid" />
              <ChipField source="status" />
              <FunctionField source="id" label="Assets" render={order => (<>
                <Button variant="contained" href={`${MOD_API_URL}/assets/${order.id}.zip`}><ArchiveIcon style={{marginRight: 5}} />Zip</Button>
                <Button variant="link" href={`/order/${order.id}/download`} target="_blank">Download</Button>
                </>)}/>
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={10}>
            <FunctionField source="maps" label="Map" render={(r) => {
              setOrder(r);

              if (!Object.keys(order).length) {
                return <Box>loading order</Box>;
              }

              return (<>
                <Typography>Provenance Maps</Typography>
                <ProvenanceMap geojson_url={r.aoi_geojson_path}
                  layers={
                    Object.keys(provenance).length ? 
                    Object.keys(provenance).map((collection)=>({
                      id: collection,
                      type: 'raster',
                      url: provenance[collection][0].tilejson_url,
                      animate: provenance[collection].length > 1 ? provenance[collection] : null,
                      visibility: collection === "io-monitor-orders" ? "visible" : "none"
                    })) : 
                    [{
                        id: 'io-monitor-orders',
                        type: 'raster',
                        visibility: "visible",
                        url: `${MOD_API_URL}/assets/${order.id}/tilejson.json`
                    }]
                  }
                  loading={loading}
                />
            </>)
            }} />
          </Grid>
        </Grid>
    </Show>
  )
}