import * as React from "react";

import { navigate } from 'gatsby';
import { Admin, Resource, useStore } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { MemoryRouter } from 'react-router-dom'
import { QueryClient } from 'react-query';

import Seo from "../../sections/SEO";
import ioTheme from "../../theme";
import "../../style/style.css";

import CommentIcon from '@mui/icons-material/Comment';
import SellIcon from '@mui/icons-material/Sell';

import { AdminLayout } from '../../components/admin/AdminLayout';
import { FeedbackList } from '../../components/admin/FeedbackList';
import { FeedbackShow } from '../../components/admin/FeedbackShow';
import { OrderList } from '../../components/admin/OrderList';
import { OrderShow } from '../../components/admin/OrderShow';
import { useAuth0 } from '../../hooks/use-auth0';
import yn from "yn";

const MOD_API_URL = process.env.GATSBY_MOD_API_URL;
const DISABLE_ADMIN = yn(process.env.GATSBY_DISABLE_ADMIN);

const AdminApp = () => {
  const { authFetch, authProvider } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useStore('sidebar.open');

  const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0 // set to zero so email un-redaction works
        },
    },
});
  const dataProvider = simpleRestProvider(`${MOD_API_URL}/admin`, authFetch);

  React.useEffect(() => {
    setSidebarOpen(false);
    // default sidebar to closed
  }, [setSidebarOpen]);

  React.useEffect( redirectIfDisableAdmin => {
    // redirect client side
    // can't do this in the component, because of SSR build
    if(DISABLE_ADMIN) {
      navigate(`/`);
    }
  }, []);

  if (DISABLE_ADMIN) {
    return (<h1>Admin Disabled</h1>)
  }
  return (
    <MemoryRouter>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        queryClient={queryClient}
        layout={AdminLayout}
        theme={ioTheme}
      >
        <Resource name="orders" list={OrderList} show={OrderShow} icon={SellIcon}  />
        <Resource name="feedback" list={FeedbackList} show={FeedbackShow} icon={CommentIcon} options={{ label: 'Feedback' }}/>

      </Admin>
    </MemoryRouter>
  )
};

export default AdminApp;

export const Head = () => (
  <Seo />
)