import * as React from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  Checkbox,
  Grid,
  Input,
  Switch
} from "@mui/material";

import {useInterval} from '../../hooks/use-interval';

function AnimationControl({layers, loading, mapRef, position}) {
  const animatedLayers = layers.filter((l) => l.animate);

  const [firstLoad, setFirstLoad] = React.useState(true);
  const [visibleIds, setVisibleIds] = React.useState(layers.reduce((acc, l) => ({ ...acc, [l.id]: false}), {}));
  const [animatedIds, setAnimatedIds] = React.useState(animatedLayers.reduce((acc, l) => ({ ...acc, [l.id]: false}), {}));
  const [animationRunning, setIsRunning] = React.useState(false);
  const [currentFrame, setCurrentFrame] = React.useState(animatedLayers.reduce((acc, l) => ({ ...acc, [l.id]: 0}), {}));
  const [delay, setDelay] = React.useState(1000);
  const map = mapRef.current?.getMap(); // need to get the map context from the ref

  const onFrameChange = (id, f) => {
    // uses [0-(length-1)] values
    const layer = layers.find((l) => l.id === id);
    if (!layer || !f) { return null }

    if(map) {
      // hide all frames for this layer
      layer.animate.forEach((a, i) => {
        const k = `${id}-animate-${i}`;
        map.setLayoutProperty(k, 'visibility', 'none');
      })
      // draw just the next one
      map.setLayoutProperty(`${id}-animate-${f}`, 'visibility', 'visible');
    }
  };

  useInterval(() => {
    Object.keys(animatedIds).filter((k) => animatedIds[k]).forEach((id) => {
      // animates all layers at the same rate, probably should be individual   
      const layer = layers.find((l) => l.id === id);
      let nextFrame = currentFrame[id]+1;
      if ((nextFrame < 0) || (nextFrame > layer.animate.length-1)) {
        nextFrame = 0;
      }
      
      onFrameChange(id, nextFrame); 
      setCurrentFrame({...currentFrame, [id]: nextFrame});
    });
  }, animationRunning ? delay: null);

  const onVisibleToggle = (id) => {
    const value = !visibleIds[id];
    const layer = layers.find((l) => l.id === id);

    if (layer && layer.animate) {
      // hide all frames for this layer
      layer.animate.forEach((a, i) => {
          const f = `${id}-animate-${i}`;

          // first ensure they exist
          if (!map.getLayer(f)) {
            // add it to the map, before the last layer (should be io-monitor-orders)
            map.addLayer({id: f, source: f, type: 'raster', url: a.tilejson_url}, "io-monitor-orders")
          }

          map?.setLayoutProperty(f, 'visibility', 'none');
      })
      
      if (value) { 
        // turn on current frame
        const f = `${id}-animate-${currentFrame[id] || 0}`;
        map?.setLayoutProperty(f, 'visibility', 'visible');
      }
    } else {
      // just the one
      map?.setLayoutProperty(id, 'visibility', value ? 'visible': 'none');
    }
    
    setVisibleIds({...visibleIds, [id]: value});
    if(!value) {
      // stop animation when making layer invisible
      setAnimatedIds({...animatedIds, [id]: false});
    }
  };

  const onAnimateToggle = (id) => {
    const value = !animatedIds[id];

    // stop all animations
    let newAnimatedIds = animatedLayers.reduce((acc, l) => ({ ...acc, [l.id]: false}), {});
    // turn on just this one
    newAnimatedIds[id] = value;
    setAnimatedIds(newAnimatedIds);
    setIsRunning(value);
  };

  if(loading) {
    return (
        <div className={`maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-${position}`} style={{position: "absolute", padding: 5}}>
            <CircularProgress/>
        </div>
    ) 
  }

  if(firstLoad && layers) {
    // set orders layer to visible
    if(layers.length) {
        onVisibleToggle('io-monitor-orders');
        setFirstLoad(false);
    }
  }

  return (
    <div className={`maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-${position}`} style={{position: "absolute", padding: 5}}>
      <FormGroup>
        {layers.map(({id, animate}) => {
          let currentAnimate = animate ? animate[currentFrame[id] || 0] : {};
          let stacURL = "";
          if(currentAnimate.tilejson_url?.startsWith('https://planetarycomputer.microsoft.com')) {
            stacURL = "planetarycomputer.microsoft.com/api/stac/v1/";
          } else if(currentAnimate.tilejson_url?.startsWith('https://api.impactobservatory.com/titiler-internal')) {
            stacURL = "api.impactobservatory.com/stac-internal";
          }
          return (<>
            <FormControl component="fieldset" key={`animate-form-${id}`}>
                <Grid container>
                <Grid item>
                    <Checkbox checked={visibleIds[id] || false} size="small"
                    onChange={() => onVisibleToggle(id)}
                    />
                </Grid>
                <Grid item>
                    <FormLabel component="legend"
                    onClick={() => onVisibleToggle(id)}
                    sx={{cursor: "pointer", userSelect: "none"}}
                    >{id}</FormLabel>
                </Grid>
                <Grid item>
                    {animate && visibleIds[id] && (<>
                    <Switch checked={animatedIds[id] || false} size="small"
                        onChange={() => onAnimateToggle(id)}
                    />
                    <Input type="number" value={(currentFrame[id]||0)+1}
                        // uses [1-length] values
                        // min/max must be +-1 from the limits
                        inputProps={{min: 0, max:animate.length+1}}
                        onChange={(evt) => {
                            let nextFrame = parseInt(evt.target.value)-1;

                            if (nextFrame > animate.length-1) {
                                nextFrame = 0;
                            }
                            if (nextFrame < 0) {
                                nextFrame = animate.length-1;
                            }

                            onFrameChange(id, nextFrame);
                            setCurrentFrame({...currentFrame, [id]: nextFrame});
                        }}
                    /> / {animate.length}
                    </>)}
                </Grid>
                </Grid>
            </FormControl>
            {animate && visibleIds[id] && (
            <Box sx={{ mt: 1}}>
                <a href={`https://stac-browser.impactobservatory.com/external/${stacURL}/collections/${id}/items/${currentAnimate.item_id}`} target="_blank">{currentAnimate.item_id}</a>
            </Box>
            )}
        </>)
        })}
      </FormGroup>
    </div>
  );
}

export default React.memo(AnimationControl);