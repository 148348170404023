import * as React from "react";

import {
    Box
  } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { hasKeys, hasGeometry, isPoint } from "../../utils";
import bbox from "@turf/bbox";

import { FITBOUNDS_OPTIONS, OUTLINE_STYLE, EMPTY_GEOJSON } from '../place/MapConstants';
import LayerControl, { MAP_STYLES } from '../place/LayerControl';
import AnimationControl from './AnimationControl';

import Map, { Source, Layer, NavigationControl } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

OUTLINE_STYLE[0]['paint']['fill-opacity'] = 0;

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        '& .maplibregl-ctrl-attrib': {
            padding: '2px 2px 2px 0px',
        },
        '& .maplibregl-ctrl-attrib[open]': {
            padding: '2px 28px 2px 8px'
        },
        '& .maplibregl-ctrl-attrib-button': {
          bottom: 0,
          top: 'auto'
        },
        '& .maplibregl-ctrl-attrib-button:focus': {
          boxShadow: 'none'
        },
        '& .maplibregl-ctrl-top-left': {
            top: 10,
            left: 10,
        },
        '& .maplibregl-ctrl-bottom-left': {
            bottom: 12,
        },
        '& .maplibregl-ctrl-bottom-right': {
            bottom: 12,
        },
        '& .maplibregl-ctrl .MuiCheckbox-sizeSmall': {
            padding: "5px 2px",
        },
        '& .maplibregl-ctrl legend': {
            paddingTop: 2,
        },
        '& .maplibregl-ctrl .MuiInput-root': {
            fontSize: '0.9rem',
        },
        
    },
    overlay: {
        position: "absolute",
        display: "flex",
        alignContent: "end",
        bottom: 0,
        left: 0,
        padding: 2,
        background: '#ffffff',
        opacity: 0.65,
        textAlign: "right",
        width: '100%',
        minHeight: 12,
    },
    overlayText: {
        textAlign: "right",
        fontSize: '0.6em',
        padding: 3,
        fontFamily: "sans-serif",
        lineHeight: 1,
        color: "#323232",
        opacity: 1,
        '& > a': {
            textDecoration: "none",
        }
    }
}));

export default function ProvenanceMap(props) {
    const { width="100%", height='100%',
        geojson_url, layers, loading,
        onError=console.error, onLoad=()=>(null)
    } = props;
    const mapRef = React.useRef();
    const classes = useStyles();

    const [features, setFeatures] = React.useState(EMPTY_GEOJSON);

    React.useEffect(() => {
      if(!geojson_url || !typeof(geojson_url) === "string" || !geojson_url.startsWith('http')) {
        // this is not a change we need to fetch for
        return;
      }
      fetch(geojson_url)
        .then(async(response) => {
          if(response.ok) {
            return await response.json()
          } else {
              throw new Error("Unable to get geojson url")
          }
        })
        .then(resultData => {
          // use faster hasGeometry here, not full turf/booleanValid
          if (hasKeys(resultData) && (isPoint(resultData) || hasGeometry(resultData))) {
              setFeatures(resultData);
          } else {
              throw new Error("Invalid geojson file");
          }
        })
        .catch(e => {
          console.error(e)
          if(onError) { onError(e) }
          return 
        })
        // skip onError from the dependency list, it uses react-hook-forms setValue, and should be considered safe
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geojson_url])

    React.useEffect(() => {
      if (features === EMPTY_GEOJSON) {
        onLoad(features);
        return;
      }
      if (isPoint(features) || hasGeometry(features)) {
          if (mapRef.current) {
              const currentBbox = features.bbox || bbox(features);
              if (currentBbox.length === 4) {
                mapRef.current.fitBounds(currentBbox, FITBOUNDS_OPTIONS);
              }
          } else {
              if(onError) { onError({message: 'No map loaded, cannot fit bounds'}) }
          }
          if(isPoint(features) && onError) {
            onError({message: `Feature ${features.id} is Point, not Polygon`})
          }
          if(onLoad) { onLoad(features) }
      } else {
          if(onError) { onError({message: 'Invalid geojson data'}) }
      }
      // skip onLoad from the dependency list, it uses react-hook-forms setValue, and should be considered safe
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [features]);

    return (<Box className={classes.root}>
      <Map
          ref={mapRef}
          initialViewState={{
              // center nicely
              latitude: 15,
              longitude: -32,
              zoom: 1,
              fitBoundsOptions:{
              }
          }}
          mapLib={maplibregl}
          mapStyle={MAP_STYLES[1].uri}
          maxZoom={13}
          style={{width: width, minWidth: 400, height: height, minHeight: 800}}
          padding={10}
          attributionControl={false} // use custom
          interactive={true}
          dragRotate={false}
          touchZoomRotate={false}
          touchPitch={false}
          pitchWithRotate={false}
          styleDiffing={false}
      >
          <NavigationControl
              position="bottom-right"
              showCompass={false}    
          />
          <LayerControl defaultPosition="bottom-left"/>
          {(layers).map((l) => {
              const {id, type, animate = false, url, visibility="none"} = l;
              if(animate) {
                // create sources for each frame, but defer the layer to when it is toggled visible
                return (<>
                    {animate.map((a, i) => (
                        <Source type={type} key={`${id}-animate-${i}`} id={`${id}-animate-${i}`}
                            url={a.tilejson_url}>
                            {/* 
                            * <Layer type={type} id={`${id}-animate-${i}`} url={url} />
                            */}
                        </Source>
                    ))}
                </>)
              } else {
                // create one source and one layer
                return (
                    <Source key={id} id={id} type={type} url={url}>
                        <Layer type={type} id={id} url={url} layout={{visibility: visibility}}></Layer>
                    </Source>
                )
              }
            })}
            <Source id={'wof'} type="geojson" data={features}>
              {(OUTLINE_STYLE).map((o) => (
                  <Layer key={o.id} {...o} visibility={true} />
              ))}
          </Source>
      </Map>
      <AnimationControl position="top-left"
        layers={layers} loading={loading} mapRef={mapRef}
      />
      <div className={classes.overlay}>
        <span className={classes.overlayText}>
          Powered by Esri and Maplibre
        </span>
      </div>
    </Box>)
  }
  