import * as React from "react";

import { List, Datagrid, TextField, DateField, ChipField, ReferenceField, TextInput, SelectInput,
} from "react-admin";

export const FeedbackList = () => {

    const postFilters = [
        <TextInput label="Email" source="email" />,
        <SelectInput label="Type" source="type"
            choices={[
                { id: 'QUESTION', name: 'Question' },
                { id: 'BUG', name: 'Bug' },
            ]} />,
      ];

  return (
    <List sort={{field: "date_created", order: "DESC"}} filters={postFilters} title="Feedback">
        <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" reference="feedback" link={"show"} label={"Feedback ID"} />
            <DateField source="date_created" />
            <TextField source="email" />
            <ChipField source="type" />
            <TextField source="rating" />
        </Datagrid>
    </List>
  )
};
