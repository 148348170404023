import * as React from "react";

import {
  Box,
  Button,
  Grid,
  Link,
  Typography
} from "@mui/material";

import { SimpleShowLayout, Show, TextField, DateField, ChipField, ReferenceField } from "react-admin";

export const FeedbackShow = () => {

  return (
    <Show>
       <Grid container>
          <Grid item>
            <SimpleShowLayout>
                <ReferenceField source="id" reference="feedback" link={"show"} label={"Feedback ID"} />
                <ReferenceField source="order_id" reference="orders" link={"show"} label={"Order"} />
                <DateField source="date_created" />
                <TextField source="email" />
                <ChipField source="type" />
                <TextField source="rating" />
                <TextField source="text" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
    </Show>
  )
}