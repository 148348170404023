import * as React from "react";
import { AppBar, Layout, TitlePortal } from 'react-admin';
import { Typography } from "@mui/material";

const AdminAppBar = () => (
    <AppBar color="primary" position="fixed">
        <Typography variant="h6" style={{marginRight: 5}}>IO Monitor Admin</Typography>
        <TitlePortal />
    </AppBar>
);

export const AdminLayout = props => <Layout {...props} appBar={AdminAppBar}  />;