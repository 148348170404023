import * as React from "react";

import { List, Datagrid, BooleanInput, NumberInput, TextInput, SelectInput,
    TextField, DateField, ChipField, ReferenceField, FunctionField
} from "react-admin";
import { Link } from "@mui/material";
import { USDFormatter } from "../../utils";

import { useIOProducts } from "../../hooks/use-io-products";

export const OrderList = () => {
  const { IO_PRODUCTS } = useIOProducts();

  const postFilters = [
    <TextInput label="Order ID" source="id" alwaysOn />,
    <TextInput label="Email" source="email" />,
    <BooleanInput label="Internal" source="internal" sx={{alignItems: "center"}}/>,
    <SelectInput label="Product" source="product_id"
        choices={IO_PRODUCTS.map((p) => ({id: p.id, name: p.id}))} />,
    <TextInput label="AOI" source="aoi_label" />,
    <SelectInput label="Status" source="status"
        choices={[
            { id: 'CREATED', name: 'Created' },
            { id: 'READY', name: 'Ready' },
            { id: 'INVALID', name: 'Invalid' },
            { id: 'INITIALIZING', name: 'Initializing' },
            { id: 'RUNNING', name: 'Running' },
            { id: 'DONE', name: 'Done' },
            { id: 'ERROR', name: 'Error' },
        ]} />,
    <SelectInput label="Payment" source="paid"
        choices={[
            { id: 'NOT_PAID', name: 'Not Paid' },
            { id: 'FREE', name: 'Free' },
            { id: 'NOT_REQUIRED', name: 'Not Required' },
            { id: 'PAID', name: 'Paid' },
        ]} />,
    <NumberInput label="Cost" source="cost"
        format={v => String(v / 100)}
        parse={v => parseFloat(v) * 100}
    />,
  ];

  return (
    <List sort={{field: "date_created", order: "DESC"}}
        filters={postFilters}
    >
        <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" reference="orders" link={"show"} label={"Order ID"} />
            <DateField source="date_created" />
            <TextField source="email" />
            <TextField source="product_id" />
            <FunctionField source="aoi_label" label="AOI" render={r => (
                <Link href={`http://geojson.io/#data=data:text/x-url,${r.aoi_geojson_path}`} target="_blank">{r.aoi_label}</Link>
            )} />
            <TextField source="area" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <FunctionField source="cost" render={r => (r.cost > 0) && USDFormatter.format(r.cost / 100)}/>
            <ChipField source="paid" />
            <ChipField source="status" />
        </Datagrid>
    </List>
  )
};
